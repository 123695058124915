import styled, { css } from 'styled-components';

export const Number = styled.span`
  display: block;
  font-size: 12rem;
  line-height: 14rem;
  color: ${({ theme }) => theme.colors.gray};
  font-weight: ${({ theme }) => theme.superBold};
  font-weight: ${({ theme }) => theme.bold};
  margin-left: -7px;
  ${({ theme }) => theme.mq.s} {
    font-size: 14rem;
    line-height: 16rem;
    margin-left: -10px;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 20rem;
    line-height: 22rem;
    margin-left: -10px;
  }
`;

const Heading = styled.h1`
  text-transform: uppercase;
  font-family: ${({ theme }) => theme.fonts.subFont};
  font-weight: ${({ theme }) => theme.bold};
  font-size: 3rem;
  line-height: 4rem;
  span {
    color: ${({ theme }) => theme.colors.pink};
    font-size: ${({ theme }) => theme.fontSize.xlg};
    line-height: 4.6rem;
  }
  ${({ theme }) => theme.mq.s} {
    font-size: 4.2rem;
    line-height: 5rem;
    span {
      font-size: 5rem;
      line-height: 6rem;
    }
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 5rem;
    line-height: 6rem;
    span {
      font-size: 8rem;
      line-height: 9rem;
    }
  }
  ${({ secondary }) =>
    secondary &&
    css`
      font-size: 2.1rem;
      line-height: 3rem;
      span {
        font-size: 2.7rem;
        line-height: 3.6rem;
      }
      ${({ theme }) => theme.mq.xs} {
        font-size: 2.6rem;
        line-height: 3.6rem;
        span {
          font-size: 3.2rem;
          line-height: 4.2rem;
        }
      }
      ${({ theme }) => theme.mq.lg} {
        font-size: 3.6rem;
        line-height: 4.6rem;
        span {
          font-size: 5.2rem;
          line-height: 6rem;
        }
      }
    `}
  ${({ white }) =>
    white &&
    css`
      span {
        color: ${({ theme }) => theme.colors.white} !important;
      }
    `}
`;

export default Heading;
