import { graphql, useStaticQuery } from 'gatsby';

const useSEOData = () => {
  const {
    wpgraphql: {
      page: { seo },
    },
  } = useStaticQuery(graphql`
    query querySEO {
      wpgraphql {
        page(id: "/strona-glowna/", idType: URI) {
          seo {
            metaDesc
            opengraphDescription
            opengraphTitle
            opengraphSiteName
            opengraphImage {
              sourceUrl
            }
          }
        }
      }
    }
  `);

  return seo;
};

export default useSEOData;
