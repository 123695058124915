import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import useSEOData from 'hooks/useSEOData';

function SEO({ lang, meta, title }) {
  const seo = useSEOData();

  const ogImage = seo.opengraphImage
    ? { property: `og:image`, content: seo.opengraphImage.sourceUrl }
    : {};

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || seo.opengraphTitle}
      script={
        [
          // {
          //   type: 'text/javascript',
          //   innerHTML: `var _mtm = window._mtm = window._mtm || [];
          //   _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
          //   var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
          //   g.async=true; g.src='https://user.aitnetapp.com/js/container_Zk7PL6ui.js'; s.parentNode.insertBefore(g,s);`,
          // },
          // {
          //   type: 'text/javascript',
          //   innerHTML: `var _paq = window._paq = window._paq || [];_paq.push(['trackPageView']); _paq.push(['enableLinkTracking']); (function() { var u="https://user.aitnetapp.com/"; _paq.push(['setTrackerUrl', u+'matomo.php']); _paq.push(['setSiteId', '9']); var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0]; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s); })();`,
          // },
        ]
      }
      meta={[
        {
          name: `robots`,
          content: `index, follow`,
        },
        {
          name: `description`,
          content: seo.metaDesc || seo.opengraphDescription,
        },
        {
          property: `og:title`,
          content: seo.opengraphTitle,
        },
        {
          property: `og:description`,
          content: seo.opengraphDescription || seo.metaDesc,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: `indigital.pl`,
        },
        {
          name: `twitter:title`,
          content: seo.opengraphTitle,
        },
        {
          name: `twitter:description`,
          content: seo.metaDesc || seo.opengraphDescription,
        },

        ogImage,
      ].concat(meta)}
    >
      <meta
        name="google-site-verification"
        content="-c3D98mr2Pv9HzWKxQHLRwRC6GEWfKRR60QQfIDUTnI"
      />
      <meta
        name="facebook-domain-verification"
        content="la9gtpmxf0bdp15wo2bksmmrrf6c5k"
      />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `pl`,
  meta: [],
};

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default SEO;
